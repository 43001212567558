<!--
 * @Author: your name
 * @Date: 2022-03-28 13:22:51
 * @LastEditTime: 2022-03-31 14:19:24
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \syg_pc_web\src\views\setting\user_information.vue
-->
<template>
  <div class="body">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <!-- 用户管理 -->
      <el-tab-pane label="用户管理" name="user">

        <div style="margin-left: 100px; margin-top: 10px">
          <div class="PWD">
            <div class="password">
              <p>
                <img :src=img alt="" width="80px;">
              </p>
              <p>用户名：{{ user_name }}</p>
              <p>联系方式：{{ tel }}</p>
              <!-- <el-button round @click="person_info_save = true">修改信息</el-button> -->
            </div>

          </div>

        </div>

      </el-tab-pane>
      <!-- 实名认证 -->
      <el-tab-pane label="实名认证" name="real">
        <p style="width: 80% ">
          <el-radio-group v-model="authentication" style="margin-left:30px;margin-bottom: 30px;float: left;"
            @change="authenticationClick">
            <el-radio-button label="1">个人用户</el-radio-button>
            <el-radio-button label="2">企业用户</el-radio-button>
          </el-radio-group>
        </p>
        <br>
        <!-- 个人用户 -->
        <div style="width: 80% !important;margin: auto;text-align: left;margin-top:60px;" v-if="authentication == 1">
          <div class="bank" v-if="authentication_status == 1">
            <div class="password">
              <p>
                <img src="../../assets/img/exclamatory_mark.png" alt="" width="80px;">
              </p>
              <p class="unbound" :style="{color:authentication_color}">{{ authentication_title }}</p>
              <p @click="authentication_status = 3">去认证 <span class=" el-icon-d-arrow-right"
                  @click="authentication_status = 3"></span></p>
            </div>

          </div>
          <div class="bank" v-if="authentication_status == 2">
            <div class="password">
              <p>
                <img src="../../assets/img/check_sign.png" alt="" width="80px;">
              </p>
              <p class="unbound" :style="{color:authentication_color}">{{ authentication_title }}</p>
              <p class="real_name">姓名：{{ individual_user.name }}</p>
              <p>身份证号：{{ individual_user.number }}</p>
              <p>身份证件证件：{{ individual_user.idpic}}</p>
            </div>

          </div>
          <el-form :model="band_data" ref="articleForm" style="margin-left: 200px;" v-if="authentication_status == 3">
            <el-form-item label="姓名:">
              <el-input v-model="individual_user.name" type="text" style="width: 300px; float: left;margin-left: 30px">
              </el-input>
            </el-form-item>
            <el-form-item label="身份证号:">
              <el-input v-model="individual_user.number" type="text" style="width: 300px; float: left;margin-left: 3px">
              </el-input>
            </el-form-item>
            <el-form-item label="身份证件:">

              <el-upload ref="upload" :action="url" list-type="picture-card" :limit="2" :on-progress="uploading"
                :file-list="imgList" accept="image/jpg,image/png,image/jpeg" :on-remove="removeImg"
                :headers="{'token':token}" :on-success="fileSuccess" :on-error="imgError" :on-exceed="limitAlert"
                style="height: 60px;margin-top: -10px;">
                <i slot="default" class="el-icon-plus"></i>
              </el-upload>

            </el-form-item>


            <el-form-item>
              <el-button type="primary" @click="preservation_save()" style="margin-left:100px; margin-top:100px;">保存
              </el-button>

            </el-form-item>
          </el-form>
        </div>
        <!-- 企业用户 -->
        <div style="width: 80% !important;margin: auto;text-align: left;margin-top:60px;" v-if="authentication == 2">
          <div class="bank" v-if="enterprise_status == 1">
            <div class="password">
              <p>
                <img src="../../assets/img/exclamatory_mark.png" alt="" width="80px;">
              </p>
              <p class="unbound" :style="{ color:enterprise_color }">{{ enterprise_title }}</p>
              <p @click="enterprise_status = 3">去认证 <span class=" el-icon-d-arrow-right"
                  @click="enterprise_status = 3"></span></p>
            </div>

          </div>
          <div class="bank" v-if="enterprise_status == 2">
            <div class="password">
              <p>
                <img src="../../assets/img/check_sign.png" alt="" width="80px;">
              </p>
              <p class="unbound" :style="{color:enterprise_color}">{{enterprise_title }}</p>
              <p class="real_name">企业名称：{{ enterprise_certification.c_name }}</p>
              <p>公司地址：{{ enterprise_certification.c_addr }}</p>
              <p>企业性质：{{ enterprise_certification.c_properties}}</p>
              <p>统一社会信用代码：{{ enterprise_certification.c_id}}</p>
              <p>联系人：{{ enterprise_certification.l_person}}</p>
              <p>联系方式：{{ enterprise_certification.l_tel}}</p>
              <p>营业执照：{{ enterprise_certification.status}}</p>
            </div>

          </div>
          <el-form :model="enterprise_certification" ref="articleForm" style="margin-left: 200px;"
            v-if="enterprise_status == 3">
            <el-form-item label="企业名称:">
              <el-input v-model="enterprise_certification.c_name" type="text"
                style="width: 300px; float: left;margin-left: 30px">
              </el-input>
            </el-form-item>
            <el-form-item label="企业地址:">
              <el-input v-model="enterprise_certification.c_addr" type="text"
                style="width: 300px; float: left;margin-left: 3px">
              </el-input>
            </el-form-item>
            <el-form-item label="企业性质:">
              <el-input v-model="enterprise_certification.c_properties" type="text"
                style="width: 300px; float: left;margin-left: 3px">
              </el-input>
            </el-form-item>
            <el-form-item label="统一社会信用代码:">
              <el-input v-model="enterprise_certification.c_id" type="text"
                style="width: 300px; float: left;margin-left: 3px">
              </el-input>
            </el-form-item>
            <el-form-item label="联系人:">
              <el-input v-model="enterprise_certification.l_person" type="text"
                style="width: 300px; float: left;margin-left: 3px">
              </el-input>
            </el-form-item>
            <el-form-item label="联系方式:">
              <el-input v-model="enterprise_certification.l_tel" type="text"
                style="width: 300px; float: left;margin-left: 3px">
              </el-input>
            </el-form-item>
            <el-form-item label="营业执照:">

              <el-upload ref="upload" :action="url" list-type="picture-card" :limit="1"
                :on-progress="enterpriseUploading" :file-list="enterpriseImgList"
                accept="image/jpg,image/png,image/jpeg" :on-remove="removeEnterpriseImg" :headers="{'token':token}"
                :on-success="fileEnterpriseSuccess" :on-error="imgError" :on-exceed="limitEnterpriseAlert"
                style="height: 60px;margin-top: -10px;">
                <i slot="default" class="el-icon-plus"></i>
              </el-upload>

            </el-form-item>


            <el-form-item>
              <el-button type="primary" @click="company_auth()" style="margin-left:100px; margin-top:100px;">保存
              </el-button>

            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
      <!-- 银行账户 -->
      <el-tab-pane label="银行账户" name="bank">
        <div style="margin-left: 100px; margin-top: 10px">
          <div class="bank" v-if="unbound == 1">
            <div class="password">
              <p>
                <img src="../../assets/img/exclamatory_mark.png" alt="" width="80px;">
              </p>
              <p class="unbound" :style="{color:color}">{{ unbound_title }}</p>
              <p @click="unbound = 3">去绑定 <span class=" el-icon-d-arrow-right" @click="unbound = 3"></span>
              </p>
            </div>

          </div>
          <!-- 添加数据显示 -->
          <div class="bank" v-if="unbound == 2">
            <div class="password">
              <p>
                <img src="../../assets/img/check_sign.png" alt="" width="80px;">
              </p>
              <p class="unbound" :style="{color:color}">{{ unbound_title }}</p>
              <p class="real_name">开户人：{{ bank_info.real_name }}</p>
              <p>银行：{{ bank_info.name }}</p>
              <p>账开户：{{ bank_info.bank_addr }}</p>
              <p>银行卡号：{{ bank_info.banknum}}</p>
            </div>

          </div>
          <!-- 提交表单 -->
          <div style="width: 80%;margin: auto;text-align: left;" v-if="unbound == 3">

            <el-form :model="band_data" ref="articleForm" style="margin-left: 200px;">
              <el-form-item label="开户人:">
                <el-input v-model="band_data.real_name" type="text"
                  style="width: 300px; float: left;margin-right: 300px">
                </el-input>
              </el-form-item>
              <el-form-item label="银                       行:">
                <el-select v-model="band_data.bank_id" placeholder="请选择"
                  style="width: 300px; float: left;margin-right: 300px">
                  <el-option v-for="item in bank_list" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="开户行:">
                <el-input v-model="band_data.bank_addr" type="text"
                  style="width: 300px;float: left;margin-right: 300px">
                </el-input>
              </el-form-item>
              <el-form-item label="银行卡号:">
                <el-input v-model="band_data.banknum" type="text" style="width: 300px;float: left;margin-right: 300px">
                </el-input>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="preservation()" style="margin-left:100px;">保存</el-button>
              </el-form-item>
            </el-form>

          </div>
        </div>

      </el-tab-pane>

    </el-tabs>
    <el-dialog title="保存银行卡" :visible.sync="save_bank" top="50px" :close-on-click-modal="false" width="40%">
      <el-form ref="articleForm" style="width:100%;margin:0 auto">

        <el-form-item style="margin-top:5px">

          <el-button @click="save_bank = false">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="实名认证" :visible.sync="authentication_save" top="50px" :close-on-click-modal="false" width="40%">
      <el-form ref="articleForm" style="width:100%;margin:0 auto">

        <el-form-item style="margin-top:5px">

          <el-button @click="authentication_save = false">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="实名认证" :visible.sync="enterprise_save" top="50px" :close-on-click-modal="false" width="40%">
      <el-form ref="articleForm" style="width:100%;margin:0 auto">

        <el-form-item style="margin-top:5px">

          <el-button @click="enterprise_save = false">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="修改个人信息" :visible.sync="person_info_save" top="50px" :close-on-click-modal="false" width="40%">
      <el-form :model="band_data" ref="articleForm" style="margin-left: 150px;width:80%;margin:0 auto">
        <el-form-item label=" 姓名:">
          <el-input v-model="individual_user.name" type="text" style="width: 300px; float: left;margin-left: 30px">
          </el-input>
        </el-form-item>
        <el-form-item label="头像:">

          <el-upload ref="upload" :action="url" list-type="picture-card" :limit="1" :on-progress="personUploading"
            :file-list="personImgList" accept="image/jpg,image/png,image/jpeg" :on-remove="removePersonImg"
            :headers="{'token':token}" :on-success="filePersonSuccess" :on-error="imgError"
            :on-exceed="limitPersonAlert" style="height: 60px;margin-top: -10px;float: left;margin-left: 30px">
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>

        </el-form-item>


        <el-form-item>
          <el-button type="primary" @click="preservation_save()" style="margin-left:-50px; margin-top:100px;">保存
          </el-button>

        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import {
    bankManageinfo,
    bankManage,
    bankList,
    personAuth,
    personAuthinfo,
    companyAuthinfo,
    companyAuth,
    personInfo,
    personCinfo
  } from "@/api/agentApi.js";
  export default {
    data() {
      return {
        activeName: 'user', //tab 默认显示
        person_info_save: false,
        img: '', //用户头像
        user_name: '', //用户昵称
        tel: '', //用户手机号
        unbound: '', //银行卡绑定状态
        user_id: '', //用户id
        unbound_title: '', //状态提示语
        color: '', //状态提示语颜色
        save_bank: false, //保存银行卡成功 弹窗
        bank_info: {
          real_name: '',
          name: '',
          bank_addr: '',
          banknum: ''
        }, //银行卡信息
        band_data: {
          real_name: '',
          bank_id: '',
          bank_addr: '',
          banknum: '',
        }, //保存银行卡数据
        bank_list: [{}], //银行列表
        authentication: 1, //实名认证  默认
        authentication_status: 1, //样式显示
        authentication_color: '', //标题颜色
        authentication_title: '', //状态提示语
        authentication_save: false, //保存个人认证成功 弹窗
        // url: this.$url + '/upload/img', //上传图片请求地址
        url: this.$url + 'upload/img', //上传图片请求地址(本地)
        token: localStorage.getItem('token'), //token
        imgList: [],
        fileList: [], //图片数组
        imgData: [],
        individual_user: {
          name: '',
          number: '',
          idpic: '',
        }, //个人实名认证
        enterprise_status: '', //样式显示
        enterprise_color: '', //标题颜色
        enterprise_title: '', //状态提示语
        enterprise_save: false, //保存企业认证成功 弹窗
        enterprise_certification: {
          c_name: '', //公司名
          c_addr: '', //公司地址
          c_properties: '', //公司性质
          c_id: '', //统一代码
          l_person: '', //联系人
          l_tel: '', //联系电话
          status: '', //状态
          c_pic: '', //营业执照
        },
        enterpriseImgData: [],
        enterpriseImgList: [],
        enterpriseFileList: [], //图片数组
        personImgData: [],
        personImgList: [],
        personFileList: [], //图片数组
      };
    },
    mounted() {
      const user_info = JSON.parse(localStorage.getItem("userInfo"));
      this.tel = user_info.tel;
      this.person_info()
    },
    methods: {
      //tabs  选中值 判断
      handleClick(tab) {
        if (tab.name == 'bank') {
          this.bank_manage_info();
          this.get_bank_list();
        } else if (tab.name == 'real') {
          this.person_auth_info();
        } else if (tab.name == 'user') {
          this.person_info();
        }
      },
      //个人 企业 选择 获取值
      authenticationClick: function (select) {
        this.authentication = select;

        if (select == 2) {
          this.company_auth_info()
        }
      },

      person_info() {
        const data = {};
        personInfo(data).then((res) => {
            if (res.status.code == 1) {
              this.img = res.data.hpic;
              this.tel = res.data.tel;
              this.user_name = res.data.userName;
            } else {
              this.$message.error(res.status.msg);
            }
          })
          .catch((err) => {
            console.log(res);
          });
      },
      person_info_up() {
        const data = {};
      },
      //银行卡信息
      bank_manage_info() {
        const data = {};
        bankManageinfo(data)
          .then((res) => {
            if (res.status.code == 1) {
              this.individual_user.name = res.data.r_name;
              this.individual_user.name = res.data.r_id;
              if (res.data.status == 3) {
                this.unbound = 1;
                this.unbound_title = '未绑定';
                this.color = '#D9001B';
              } else if (res.data.status == 1) {
                this.unbound = 2;
                this.unbound_title = '已绑定！';
                this.color = '#70B603';
                this.bank_info.real_name = res.data.real_name;
                this.bank_info.name = res.data.name;
                this.bank_info.bank_addr = res.data.bank_addr;
                this.bank_info.banknum = res.data.banknum;
              } else if (res.data.status == 0) {
                this.unbound = 2;
                this.unbound_title = '审核中';
                this.color = '#70B603';
                this.bank_info.real_name = res.data.real_name;
                this.bank_info.name = res.data.name;
                this.bank_info.bank_addr = res.data.bank_addr;
                this.bank_info.banknum = res.data.banknum;
              } else if (res.data.status == 2) {
                this.unbound = 1;
                this.unbound_title = '未通过';
                this.color = '#D9001B';
              }
            } else {
              this.$message.error(res.status.msg);
            }
          })
          .catch((err) => {
            console.log(res);
          });
      },
      //保存银行卡
      preservation() {
        if (this.band_data.real_name == '') {
          this.$message.error("开户人不能为空");
        } else if (this.band_data.bank_id == '') {
          this.$message.error("银行不能为空");
        } else if (this.band_data.bank_addr == '') {
          this.$message.error("开户行不能为空");
        } else if (this.band_data.banknum == '') {
          this.$message.error("银行卡号不能为空");
        } else {
          const data = this.band_data;
          bankManage(data)
            .then((res) => {

              if (res.status.code == 1) {
                this.save_bank = true;
                this.bank_manage_info();
              } else {
                this.$message.error(res.status.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err);
            });
        }

      },
      get_bank_list() {
        const data = {};
        bankList(data).then((res) => {
            if (res.status.code == 1) {
              this.bank_list = res.data;
            } else {
              this.$message.error(res.status.msg);
            }
          })
          .catch((err) => {
            this.$message.error(err);
          });
      },
      //个人认证信息
      preservation_save() {

        if (this.individual_user.name == '' || this.individual_user.name == undefined) {
          this.$message.error("姓名不能为空");
        } else if (this.individual_user.number == '' || this.individual_user.number == undefined) {
          this.$message.error("身份证号不能为空");
        } else if (this.imgData.length < 2) {
          this.$message.error("身份证件需要上传正反面");
        } else {
          const data = {
            r_name: this.individual_user.name,
            r_id: this.individual_user.number,
            idpic_z: this.imgData[0].url,
            idpic_b: this.imgData[1].url,
          };

          personAuth(data).then((res) => {

              if (res.status.code == 1) {
                this.authentication_save = true;
                this.person_auth_info()
              } else {
                this.$message.error(res.status.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err);
            });
        }
      },

      //获取个人认证
      person_auth_info() {
        const data = {};
        personAuthinfo(data).then((res) => {
            if (res.status.code == 1) {
              if (res.data.status == 3) {
                this.authentication_status = 1;
                this.authentication_title = '未认证';
                this.authentication_color = '#D9001B';
              } else if (res.data.status == 1) {
                this.authentication_status = 2;
                this.authentication_title = '已认证！';
                this.authentication_color = '#70B603';
                this.bank_info.real_name = res.data.r_name;
                this.individual_user.name = res.data.name;
                this.individual_user.number = res.data.r_id;
                this.individual_user.idpic = '已提交';
              } else if (res.data.status == 0) {
                this.authentication_status = 2;
                this.authentication_title = '审核中';
                this.authentication_color = '#70B603';
                this.individual_user.name = res.data.r_name;
                this.individual_user.number = res.data.r_id;
                this.individual_user.idpic = '已提交';
              } else if (res.data.status == 2) {
                this.authentication_status = 1;
                this.authentication_title = '未通过';
                this.authentication_color = '#D9001B';
              }
            } else {
              this.$message.error(res.status.msg);
            }
          })
          .catch((err) => {
            this.$message.error(err);
          });
      },

      //获取企业认证
      company_auth_info() {
        const data = {};
        companyAuthinfo(data).then((res) => {
            if (res.status.code == 1) {
              if (res.data.status == 3) {
                this.enterprise_status = 1;
                this.enterprise_title = '未认证';
                this.enterprise_color = '#D9001B';
              } else if (res.data.status == 1) {
                this.enterprise_status = 2;
                this.enterprise_title = '已认证！';
                this.enterprise_color = '#70B603';
                this.enterprise_certification.c_name = res.data.c_name;
                this.enterprise_certification.c_addr = res.data.c_addr;
                this.enterprise_certification.c_properties = res.data.c_properties;
                this.enterprise_certification.c_id = res.data.c_id;
                this.enterprise_certification.l_person = res.data.l_person;
                this.enterprise_certification.l_tel = res.data.l_tel;
                this.enterprise_certification.status = '已审核';
              } else if (res.data.status == 0) {
                this.enterprise_status = 2;
                this.enterprise_title = '审核中';
                this.enterprise_color = '#70B603';
                this.enterprise_certification.c_name = res.data.c_name;
                this.enterprise_certification.c_addr = res.data.c_addr;
                this.enterprise_certification.c_properties = res.data.c_properties;
                this.enterprise_certification.c_id = res.data.c_id;
                this.enterprise_certification.l_person = res.data.l_person;
                this.enterprise_certification.l_tel = res.data.l_tel;
                this.enterprise_certification.status = '已提交';
              } else if (res.data.status == 2) {
                this.enterprise_status = 1;
                this.enterprise_title = '未通过';
                this.enterprise_color = '#D9001B';
              }
            } else {
              this.$message.error(res.status.msg);
            }
          })
          .catch((err) => {
            this.$message.error(err);
          });
      },
      //企业认证信息
      company_auth() {
        if (this.enterprise_certification.c_name == '' || this.enterprise_certification.c_name == undefined) {
          this.$message.error("企业名称不能为空");
        } else if (this.enterprise_certification.c_addr == '' || this.enterprise_certification.c_addr == undefined) {
          this.$message.error("企业地址不能为空");
        } else if (this.enterprise_certification.c_properties == '' || this.enterprise_certification.c_properties ==
          undefined) {
          this.$message.error("企业性质不能为空");
        } else if (this.enterprise_certification.c_id == '' || this.enterprise_certification.c_id == undefined) {
          this.$message.error("统一社会信用代码不能为空");
        } else if (this.enterprise_certification.l_person == '' || this.enterprise_certification.l_person ==
          undefined) {
          this.$message.error("联系人不能为空");
        } else if (this.enterprise_certification.l_tel == '' || this.enterprise_certification.l_tel ==
          undefined) {
          this.$message.error("联系方式不能为空");
        } else if (this.enterpriseImgData.length < 1) {
          this.$message.error("营业执照需要上传");
        } else {
          const data = {
            c_name: this.enterprise_certification.c_name,
            c_addr: this.enterprise_certification.c_addr,
            c_properties: this.enterprise_certification.c_properties,
            c_id: this.enterprise_certification.c_id,
            l_person: this.enterprise_certification.l_person,
            l_tel: this.enterprise_certification.l_tel,
            c_pic: this.enterpriseImgData[0].url,
          };
          companyAuth(data).then((res) => {

              if (res.status.code == 1) {
                this.enterprise_save = true;
                this.company_auth_info()
              } else {
                this.$message.error(res.status.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err);
            });
        }
      },


      //图片上传
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },

      uploading(event, file, fileList) { //判断用户上传文件类型和大小
        if (file.raw.type == 'image/jpeg' || file.raw.type == 'image/jpg' || file.raw.type == 'image/png') {
          const size = file.size / 1024
          if (size > 1024) {
            this.$message.error('图片大小不能超过1M！')
            this.$refs.upload.abort() //取消上传
            this.imgList = fileList.filter(item => item.uid != file.uid)
          } else {
            this.$message.success("图片上传成功！")
          }
        } else {
          this.$message.error("只可以上传jpeg、jpg和png格式图片，请重新上传！")
          this.imgList = fileList.filter(item => item.uid != file.uid)
          this.$refs.upload.abort() //取消上传
        }
      },
      limitAlert() { //展示图片超过张数提醒
        this.$message.error('最多能上传2张图片！')
      },

      imgError(err, file, fileList) { //展示图片上传失败提醒
        this.$message.error('图片上传失败！')
      },
      removeImg(file, fileList) { //展示图片删除提醒
        this.$message.success('图片删除成功！')
        this.imgData = this.imgData.filter(item => item.imgId != file.uid) //从imgData 过滤掉 item.imgId 不等于 file.uid
      },
      fileSuccess(response, file, enterpriseFileList) { //展示图片上传成功
        let data = {
          imgId: file.uid,
          url: response.data.url
        }
        this.imgData.push(data); //把上传成功的返回的地址（一般是oss地址） 赋值给imgData
      },
      enterpriseUploading(event, file, enterpriseFileList) { //判断用户上传文件类型和大小
        if (file.raw.type == 'image/jpeg' || file.raw.type == 'image/jpg' || file.raw.type == 'image/png') {
          const size = file.size / 1024
          if (size > 1024) {
            this.$message.error('图片大小不能超过1M！')
            this.$refs.upload.abort() //取消上传
            this.enterpriseImgList = enterpriseFileList.filter(item => item.uid != file.uid)
          } else {
            this.$message.success("图片上传成功！")
          }
        } else {
          this.$message.error("只可以上传jpeg、jpg和png格式图片，请重新上传！")
          this.enterpriseImgList = enterpriseFileList.filter(item => item.uid != file.uid)
          this.$refs.upload.abort() //取消上传
        }
      },
      limitEnterpriseAlert() { //展示图片超过张数提醒
        this.$message.error('最多能上传1张图片！')
      },

      fileEnterpriseSuccess(response, file, enterpriseFileList) { //展示图片上传成功
        let data = {
          imgId: file.uid,
          url: response.data.url
        }
        this.enterpriseImgData.push(data); //把上传成功的返回的地址（一般是oss地址） 赋值给imgData
      },
      removeEnterpriseImg(file, enterpriseFileList) { //展示图片删除提醒
        this.$message.success('图片删除成功！')
        this.enterpriseImgData = this.enterpriseImgData.filter(item => item.imgId != file
          .uid) //从imgData 过滤掉 item.imgId 不等于 file.uid
      },

      personUploading(event, file, enterpriseFileList) { //判断用户上传文件类型和大小
        if (file.raw.type == 'image/jpeg' || file.raw.type == 'image/jpg' || file.raw.type == 'image/png') {
          const size = file.size / 1024
          if (size > 1024) {
            this.$message.error('图片大小不能超过1M！')
            this.$refs.upload.abort() //取消上传
            this.enterpriseImgList = enterpriseFileList.filter(item => item.uid != file.uid)
          } else {
            this.$message.success("图片上传成功！")
          }
        } else {
          this.$message.error("只可以上传jpeg、jpg和png格式图片，请重新上传！")
          this.enterpriseImgList = enterpriseFileList.filter(item => item.uid != file.uid)
          this.$refs.upload.abort() //取消上传
        }
      },
      limitPersonAlert() { //展示图片超过张数提醒
        this.$message.error('最多能上传1张图片！')
      },

      filePersonSuccess(response, file, enterpriseFileList) { //展示图片上传成功
        let data = {
          imgId: file.uid,
          url: response.data.url
        }
        this.enterpriseImgData.push(data); //把上传成功的返回的地址（一般是oss地址） 赋值给imgData
      },
      removePersonImg(file, enterpriseFileList) { //展示图片删除提醒
        this.$message.success('图片删除成功！')
        this.enterpriseImgData = this.enterpriseImgData.filter(item => item.imgId != file
          .uid) //从imgData 过滤掉 item.imgId 不等于 file.uid
      },

    }
  }

</script>

<style lang="scss" scoped>
  .PWD {

    width: 40%;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid #cccccc;
    margin-top: 100px;

    .password {
      align-items: center;


      //   text-align: center;

      p {
        // align-self: cent;
        text-align: center;

        // color: cadetblue;
      }
    }
  }

  .bank {

    width: 40%;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid #cccccc;
    margin-top: 100px;

    .password {
      align-items: center;

      .unbound {
        font-size: 20px;
      }

      .real_name {
        margin-top: 20p !important;
      }

      p {
        text-align: center;
        // color: cadetblue;
      }

    }


  }

  .avatar-uploader .el-upload {
    border: 1px dashed #080101 !important;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    border: 1px dashed #d9d9d9 !important;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

</style>
